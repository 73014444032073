.brandList .sliderImage{display: flex;width: 162px;height: 40px;align-items: center;justify-content: center;position: relative;}
.brandList .sliderImage img{object-fit: contain;}
.brandList { width: 100%; overflow: hidden; position: relative;}
.marqueeWrapper {width: 100%;overflow: hidden;}
.marqueeGroup { display: flex; flex-shrink: 0; align-items: center; justify-content: space-around; gap: 2rem; animation: scroll 10s linear infinite; }
.marqueeGroup:hover {animation-play-state: paused;}
.sliderImage {  display: flex;  align-items: center;  justify-content: center;  flex: 0 0 130px;}

@keyframes scroll {
	0% {
		transform: translateX(0);
    }
    100% {
		transform: translateX(-50%);
    }
}
@media (max-width: 1024px) {
	.marqueeGroup {gap: 1.5rem;}
}
@media screen and (max-width: 768px){
	.brandList{grid-gap: 10px;}
	.brandList li{flex-shrink: 0;}
	.marqueeGroup { gap: 1rem; }
}
@media screen and (max-width: 768px){
	.brandList .sliderImage img{max-width: 100px;width: 100%;}
}