.mediaCoverage{display: flex;position: relative;gap: 15px;white-space: nowrap;overflow: auto;scroll-behavior: smooth;} 
.mediaCoverage::-webkit-scrollbar{height: 0px;} 
.tile{padding: 20px;border-radius: 10px;width: 100%;min-width: 274px;} 
.tile .tileFig{margin-bottom: 20px;height: 40px;width: 100%;display: flex;align-items: center;} 
.tile .tileFig img{object-fit: cover;width: auto;} 
.tile .desc{overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;white-space: break-spaces;} 
.viewFullArticle{display: flex;align-items: center;justify-content: space-between;cursor: pointer;} 
.viewFullArticle span{text-transform: capitalize;} 

@media screen and (max-width:768px) {     
    .mediaCoverage{white-space: nowrap;overflow: auto;scroll-behavior: smooth;display: block;}     
    .mediaCoverage::-webkit-scrollbar{height: 0px;}     
    .tile{margin-left: 15px;padding: 15px;width: 273px;display: inline-block;}     
    .tile:first-child{margin-left: 0px;}     
    .tile .desc{white-space: break-spaces;} 
}