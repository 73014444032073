.whyItem{display: flex;flex-direction: column;align-items: center;border-right: 1px solid #9996;}
.whyItem:last-child{border-right: none;}
.whyItem figure{margin: 16px 0px 12px;}
.whyItem .title{font-size: 16px;}
.whyInteriorBox{padding: 46px 0;overflow: auto;}
.whyChooseBtn{padding: 0px 35px;line-height: 50px;}

@media screen and (max-width: 768px){
	.whyInteriorBox{padding: 24px 0 5px;margin-bottom: 20px;display: flex;}
	.whyItem {padding: 16px;margin-bottom: 0;min-width: 230px; border-radius: 5px;}
	.whyItem figure{margin: 0px 0px 12px;}
	.whyItem .title{margin-bottom: 12px;font-size: 14px;}
	.whyItem .subtitle{font-size: 12px;line-height: 16px;}
}
@media screen and (max-width:540px){
	.whyChooseBtn{width: 100%;justify-content: center;text-align: center;}
}