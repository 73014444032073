.setsPartList{display: flex;justify-content: center;grid-gap: 24px;}
.experinceList .setsList{background: #FFF9FA;box-shadow: 0 0 15px 0 rgba(117, 117, 117, 0.25);border-radius: 8px;}
.experinceList .setsList li{color: #f87690;font-size: 16px;font-weight: 600;border:solid 1px #ffcdd7;padding: 14px 18px; }
.experinceList .setsList li:first-child{border-radius: 10px 10px 0 0;}
.experinceList .setsList li:last-child{border-radius: 0 0 10px 10px;}
.experinceList .setsList li p{color: #666;font-size: 14px;font-weight: normal;}
.typicalExperience .setsList li{color: #666666;font-size: 16px;font-weight: 600;padding: 14px 0px; }
.typicalExperience .setsList li p{color: #a1a1a1;font-size: 14px;font-weight: normal;}
.whatSetsList li{height: 70px;display: flex;align-items: center;font-size: 16px;color: #222;justify-content: flex-end;}
.whatSetsList{padding-top:36px;}

@media screen and (max-width: 768px){
	.whatSetsList li,.experinceList .setsList li,.typicalExperience .setsList li{font-size: 10px;line-height: normal;}
	.experinceList .setsList li span,.typicalExperience .setsList li span{display: none;}
	.experinceList .setsList li p,.typicalExperience .setsList li p{font-size: 11px;line-height: normal;}
	.setsPartList{grid-gap: 10px;}
	.whatSetsList{padding-top: 57px;}
	.whatSetsList li{height: 62px;display: flex;align-items: center;justify-content: flex-end;text-align: right;}
	.experinceList .setsList li{padding: 10px;height: 62px;display: flex;align-items: center;justify-content: center;}
	.typicalExperience .setsList li{padding: 10px 0;height: 62px;display: flex;align-items: center;}
}

@media screen and (max-width: 540px){
	.whatSetsList{padding-top: 37px;}
}

@media screen and (max-width: 375px){
	.typicalExperience .subHeading{height: 40px;}
	.whatSetsList{padding-top: 57px;}
}