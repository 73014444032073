.closeBtexitpop{color: #666; border: none; background: #fff;align-self: flex-start;cursor: pointer;z-index: 2;right: 24px;top: 24px; position: absolute;width: 24px;height: 24px;border: solid 0.5px #cbcbcb;display: flex;align-items: center;justify-content: center;border-radius: 5px;}
.exitnwpopMinbox{display: flex;justify-content: center;margin: 30px auto;border-radius: 10px;}
.exitnewFormBox {padding: 24px;background: #fff;position: relative;max-width: 420px;width: 100%;    border-radius: 0 5px 5px 0;}
.exitnewFormBox .leadFormClose:before{transform: rotate(135deg);}
.exitnewFormBox .leadFormClose:after{transform: rotate(45deg);}
.exitnewFormBox .leadFormClose:after, .exitnewFormBox .leadFormClose:before {content: "";position: absolute;left: 4px;top: 11px;width: 14px;height: 1px;background: #505050;}
.exitpopLeftimgb{position: relative;border-radius: 10px 0 0 10px;}
.exitpopLeftimgb figure{background: #fff;border-radius: 5px 0 0 5px;}
.exitpopLeftimgb .exitLftpotextb {position: absolute;top: 30px;left: 30px;width: 410px;}
.exitpopLeftimgb .exitLftpotextb h4{margin-bottom: 16px;font-size: 30px;color: #60399e;font-weight: bold;line-height: 1.2;}
.exitpopLeftimgb .exitLftpotextb p{font-size: 16px;color: #000;line-height: 20px;}
.reQuestbt{padding: 14px;}
.exitpopMobres{display: none;}
@media screen and (max-width:768px){
    .exitpopLeftimgb{display: none;}
    .exitnewFormBox{border-radius: 10px;}
    .exitnewFormBox{padding: 16px;max-width: 100%;}
}
@media screen and (max-width:540px){
.exitnwpopMinbox{flex-direction: column;}
.exitpopMobres{display: block;padding: 16px;background: #e1d9ed;}
.exitpopMobres span{font-size: 16px;font-weight: bold;line-height: 1.2;color: #60399e;margin-bottom: 16px;display: block;}
.exitpopMobres .offerVld{font-size: 14px;line-height: 20px;color: #000;}
.closeBtexitpop{top: -132px;right: 16px;}
.exitPoptextmbox{width: 300px;}
.exitnwpopMinbox{width: calc(100% - 30px);border-radius: 10px;overflow: hidden; margin: 10px auto;}
.exitnewFormBox{border-radius: 0;}
.reQuestbt{padding: 10px;}
.ctaLeadForm h3{margin-bottom: 10px;}
}