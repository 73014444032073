.formLable{color: #666;font-size: 14px;position: absolute;left: 16px;top: 10px;background-color: #fff;transition: 0.3s;z-index: 0;}
.referFormItem{margin-bottom: 26px;position: relative;transition: 0.3s;}
.referFormItem input{transition: 0.3s;z-index: 2;}
.referFormItem input:focus + .formLable,.referFormItem input:valid + .formLable{padding: 0px 7px;top: -10px;left:12px;font-size: 12px;transition: 0.3s;color: #222;}
.mobileInput{display: flex;padding: 0;}
.mobileInput input{padding-left: 10px; border-radius: 0px 5px 5px 0px;border-left: none !important;}
.mobileInput .formLable{left: 75px;}
.mobileInput .dropDown{width: 100%;max-width: 65px;}
.formControl{padding: 10px;display:block;width:100%;height:40px;font-size:14px;color:#222;background:#fff;border: none; border:0.5px solid #cbcbcb;box-shadow:none;-webkit-appearance:none;-moz-appearance:none;appearance:none;background:none;border-radius: 5px;}

.dropDown{position: relative;}
.dropDown::after{content: '';pointer-events: none;position: absolute;right: 8px;top: 18px;background: url("data:image/svg+xml;charset=utf8,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L9 1' stroke='%23222222' strokeLinecap='round' strokeLinejoin='round'/%3E%3C/svg%3E") no-repeat;width: 12px;height: 7px;}
.dropDown select.formControl{padding: 10px;}

.optionalDetails{display: flex;gap: 15px;}
.optionalDetails .referFormItem{width: 100%;}
.sendInviteCheck{margin-bottom: 15px;display: inline-flex;align-items: center;gap: 10px;cursor: pointer;}
.sendInviteCheck label{cursor: pointer;}
@media screen and (max-width:540px) {
    .optionalDetails{display: block;}
}










