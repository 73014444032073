.leftCustomSlide{position: absolute;top: calc(50% - 20px);left: 15px;color: #000;width: 40px;height: 35px;background-color: rgba(248, 248, 248, 0.7);border-radius: 5px;display: flex;align-items: center;justify-content: center;font-size: 20px;cursor: pointer;transition: 0.3s;z-index: 1;}
.rightCustomSlide{position: absolute;top: calc(50% - 20px);right: 15px;color: #000;width: 40px;height: 35px;background-color: rgba(248, 248, 248, 0.7);border-radius: 5px;display: flex;align-items: center;justify-content: center;font-size: 20px;cursor: pointer;transition: 0.3s;z-index: 1;}
.left0{left: 0;top: 0;}
.right0{right: 0;top: 0;}  
@media screen and (max-width:540px) {
    .leftCustomSlide{width: 30px;height: 30px;}		
    .rightCustomSlide{width: 30px;height: 30px;}	
    .left0{left: 0;height: 36px;top: 0}
    .right0{right: 0;height: 36px;top: 0;}	
}
