.clientVideo{position: relative;}
.clientVideo::-webkit-scrollbar{height: 0px;}
.VideoBox{margin-right: 22px;box-shadow: 0 5px 15px 0 #e3e3e3;border-radius: 10px;overflow: hidden;display: inline-block;max-width: 365px;}
.VideoBox:last-child{margin-right: 0px;}
.VideoBox figure{margin-bottom: 16px;position: relative;display: flex;}
.VideoBox figure span{width: 100% !important;}
.VideoBox figure .youtubelogo{position: absolute;left: 50%; top: 50%;width: 57px;height: 40px;cursor: pointer;transform: translate(-50%, -50%);}
.videoText,.videoName{padding:0 16px;overflow: hidden;display: -webkit-box; -webkit-line-clamp: 3;-webkit-box-orient: vertical;white-space: break-spaces;}
.videoName{display: flex;justify-content: space-between;margin-top: 5px;margin-bottom: 15px;}
.realStories{position: relative;white-space: nowrap;overflow: auto;scroll-behavior: smooth;}
.realStories::-webkit-scrollbar{height: 0px;}
@media screen and (max-width:540px){
    .videoText{white-space: break-spaces;}
    .VideoBox{max-width: 100%;}
    .clientVideo{display: block;white-space: break-spaces;}
    .clientVideo a:last-child{margin-right: 0px;}
}