.discoverImgminbox{display: grid;grid-template-columns: repeat(6, 1fr);grid-gap: 24px;}
.diTileimgbox img{vertical-align: middle;min-height: 200px;max-height: 200px;width: 100%;}
.discverImgtiles{cursor: pointer;}
.discoImboxtext{padding: 10px;display: flex;align-items: center;justify-content: space-between; background: #fafafa;border-radius: 5px;}
.discoImboxtext span{margin-bottom: 5px;display: inline-block;font-size: 12px;color: #222;line-height: 12px;}
.discoImboxtext p{font-size: 16px;color: #222;line-height: 16px;font-weight: bold;}
@media screen and (max-width: 768px){
	.discoverImgminbox{gap: 16px;}
	.discoverImgminbox{overflow: auto;}
	.discverImgtiles{min-width: 200px;}
	.discoImboxtext{justify-content: center;}
	.discoImboxtext span{display: inline-block;font-size: 10px;}
	.discoImboxtext p{font-size: 14px;line-height: 16px;}
	.disTxtrigbox{display: none;}
}