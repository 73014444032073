.howItWorkMain{padding-bottom: 100px;}
.workBx{display: flex;gap: 24px;align-items: center;}
.workImg figure {max-width: 474px;width: 100%;}
.workDetails{width: 100%;}
.stepsBx{margin: 0 auto;display: flex;align-items: center;justify-content: space-between;position: relative;max-width: 420px;width: 100%;}
.stepsBx::after{content: '';position: absolute;left: 0;top: 50%;height: 1px;background-color: #666;width: 100%;}
.stepsBx li{width: 32px;height: 32px;position: relative;border-radius: 50px;background-color: #fff;display: flex;align-items: center;justify-content: center;z-index: 1;}
.stepsBx li span {background-color: #ffe7eb;border-radius: 50px;font-size: 8px;width: 16px;height: 16px;color: #666;display: flex;align-items: center;justify-content: center;}
.stepsBx li label{margin-left: -50px;position: absolute;left: 50%;bottom: -22px;white-space: nowrap;width: 100px;font-size: 12px;text-align: center;}
.stepsBx li.active span{background-color: #eb5976;color: #fff;}
.stepsBx li.active label{color: #222;}

.context{margin: 0 auto;display: block;max-width: 520px;width: 100%;}
.context li{display: none;}
.context li label{margin-bottom: 12px;text-align: center;display: block;font-size: 18px;color: #000;}
.context li span{text-align: center;display: block;height: 60px;}
.context li.active{display: block;}

@media screen and (max-width:540px) {
    .workBx{flex-direction: column;}
    .workImg figure{display: flex;align-items: center;justify-content: center;}
    .stepsBx li label{font-size: 10px;margin: 0;left: calc(50% - 20px);width: 40px;white-space: inherit;bottom: inherit;top: 30px;}
    .context li span{font-size: 14px;}
}